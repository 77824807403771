'use client'

import { cn } from '@/lib/utils/utils'
import { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { I18nValue, useI18nValue } from '../i18n/lang-context'
import Reveal from '../reveal'
import { Flex } from '../ui/flex'
import { useScrollIntersection } from '../utils/windowScroll'


const STATS: Stat[] = [
  {
    desc: {
      en: 'Of cryptocurrency holders report experiencing difficulties with their bank.',
      fr: 'Des détenteurs de cryptos déclarent connaître des difficultés avec leur banque.'
    },
    start: 0,
    end: 68,
    suffix: '%',
    precision: 0,
    duration: 1400,
    delay: 100
  },
  {
    desc: {
      en: 'Of French people declare holding crypto-assets. (KPMG 2024 study)',
      fr: 'Des français déclarent détenir des crypto-actifs. (étude KPMG 2024)'
    },
    start: 0,
    end: 12,
    suffix: '%',
    precision: 0,
    duration: 1300,
    delay: 200
  },
  {
    desc: {
      en: 'Of Bitcoin wallets are in profit.',
      fr: 'Des portefeuilles Bitcoin sont en plus-value.'
    },
    start: 0,
    end: 90,
    suffix: '%',
    precision: 0,
    duration: 1500,
    delay: 0
  }
]


export type Stat = {
  title?: I18nValue
  desc: I18nValue
  start: number
  end: number
  prefix?: string
  suffix: string
  precision: number
  duration: number
  delay?: number
}

const StatNumber = forwardRef(({ stat }: { stat: Stat }, ref: ForwardedRef<any>) => {

  const i18nValue = useI18nValue()
  const [value, setValue] = useState(stat.start)

  function counter() {
    if (stat.title) return
    let current = stat.start
    let increment = Math.max((stat.end > stat.start ? 1 : -1) * Math.pow(10, -1 * stat.precision), 0.5)
    let range = (stat.end - stat.start) / increment
    let intervalDurationStep = Math.abs(Math.floor(stat.duration / range))
    setTimeout(() => {
      let timer = setInterval(() => {
        current = Math.min(current + increment, stat.end)
        setValue(current)
        if (current >= stat.end) {
          clearInterval(timer)
        }
      }, intervalDurationStep)
    }, stat.delay || 0)
  }
  StatNumber.displayName = 'StatNumber'

  useEffect(() => { counter() }, [])

  useImperativeHandle(ref, () => ({ counter }))

  return stat.title ? (
    <div id='VALUE' className='text-center text-3xl font-semibold text-primary dark:brightness-150'>{i18nValue(stat.title) as string}</div>
  ) : (
    <div id='VALUE' className='text-3xl font-bold text-primary dark:text-primaryBright md:text-4xl md:font-semibold lg:text-5xl'>{stat.prefix}{value.toFixed(Math.max(0, stat.precision))}{stat.suffix}</div>
  )
})

function Stat({ stat, show, className = '', index = 0 }: { stat: Stat, show: boolean, className?: string, index: number }) {

  const i18nValue = useI18nValue()
  const childRef = useRef<any>()

  useEffect(() => {
    if (show) childRef.current?.counter()
  }, [show])

  return (
    <Reveal delay={100 + index * 100} translateX={100} duration={350} className={cn('z-20 flex cursor-default flex-col flex-nowrap items-center justify-center gap-2 rounded-full bg-background p-3 shadow-md dark:bg-white/5 sm:gap-4 sm:p-4 lg:p-8',
      'max-h-[160px] min-h-[160px] min-w-[160px] max-w-[160px]',
      'md:max-h-[200px] md:min-h-[200px] md:min-w-[200px] md:max-w-[200px]',
      'lg:max-h-[300px] lg:min-h-[300px] lg:min-w-[300px] lg:max-w-[300px]',
      className)}>
      <StatNumber stat={stat} ref={childRef} />
      <div id='DESC' className='text-center text-xs lg:text-sm'>{i18nValue(stat.desc) as string}</div>
    </Reveal>
  )
}

export function Stats({_STATS}: {_STATS?: Stat[]}) {

  const ref = useRef<any>()
  const { show } = useScrollIntersection(ref)

  return (
    <Flex ref={ref} id='STATS' className='w-full items-center justify-center gap-4 sm:gap-8'>
      {(_STATS || STATS).map((stat, i) => <Stat stat={stat} key={i} index={i} show={show} />)}
    </Flex>
  )
}
